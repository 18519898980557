// start script for high light header and footer menu
const currentLocation = location.href;
const menuItems = document.querySelectorAll('a');
const menuLength = menuItems.length;
for (let i = 0; i < menuLength; i++) {
  if (menuItems[i].href === currentLocation) {
    menuItems[i].style.color = '#FFB41F';
  }
}
// end script for high light header and footer menu
